<template>
  <div class="select-bundle">
    <Hero
      :description="$t('HERO_CAROUSEL_HEADER')"
      :backgroundImage="backgroundImage"
    >
      <template>
        <div>
          <h1>
            {{ $t('HERO_MESSAGE') }}
          </h1>
        </div>
      </template>
    </Hero>

    <Container>
      <Separator size="small" />
      <b-row align-v="center">
        <b-col lg="7">
          <div class="margins">
            <h3>{{ $t(`SELECT_BUNDLE.TITLE`) }}</h3>
            <p>
              {{ $t(`SELECT_BUNDLE.INTRODUCTION`) }}
            </p>
            <p>
              {{ $t(`SELECT_BUNDLE.ADDITIONAL_INFORMATION`) }}
            </p>
            <i18n path="SELECT_BUNDLE.REDIRECTION" tag="div">
              <template #link>
                <a
                  href="https://stihlgarden.se/robotgrasklippare/modeller"
                  target="_blank"
                >
                  <span class="text-strong">
                    {{ $t('SELECT_BUNDLE.CLICK_HERE') }}
                  </span>
                </a>
                <p>{{ $t(`SELECT_BUNDLE.REDIRECTION_CONTINUES`) }}</p>
              </template>
            </i18n>
            <p class="text-strong">
              {{ $t('SELECT_BUNDLE.REDUCED_AVAILABILITY') }}
            </p>
          </div>
        </b-col>
        <b-col class="d-none d-lg-block">
          <img
            src="@/assets/images/imow_models_S1.jpg"
            alt="IMOW model S1"
            loading="lazy"
          />
        </b-col>
      </b-row>

      <b-row v-if="isLoading" align-h="center">
        <b-col cols="auto">
          <Separator size="large" />
          <Loader :text="$t('LOADING')" />
        </b-col>
      </b-row>

      <Margins v-else class="margins__double">
        <div class="select-bundle__bundles">
          <BundleSection
            v-for="[key, bundles] in Object.entries(mappedBundles)"
            :key="key"
            :bundles="bundles"
          />
        </div>
      </Margins>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { GET_BUNDLES, RESET_STORE, SET_SIGNATURE_STATUS } from '@/types';
import { constants } from '@/mixins';
import { Container, Loader, Margins, Separator } from '@/components';
import { Hero, BundleSection } from '@/containers';
import HeroBackground from '@/assets/images/sai_hero.jpg';

export default {
  name: 'SelectBundle',
  mixins: [constants],
  components: {
    Hero,
    Container,
    Loader,
    Separator,
    BundleSection,
    Margins,
  },
  mounted() {
    this.RESET_STORE();
    this.SET_SIGNATURE_STATUS(null);

    this.GET_BUNDLES();
  },
  methods: {
    ...mapActions([GET_BUNDLES]),
    ...mapMutations([RESET_STORE, SET_SIGNATURE_STATUS]),
  },
  computed: {
    ...mapState(['bundles', 'isLoading']),
    backgroundImage() {
      return HeroBackground;
    },
    mappedBundles() {
      return this.filteredBundles.reduce((acc, bundle) => {
        if (bundle.additionalData.model) {
          acc[bundle.additionalData.model] = [
            ...(acc?.[bundle.additionalData.model] ?? []),
            bundle,
          ];
        }
        return acc;
      }, {});
    },
    isShowHidden() {
      return this.$route.fullPath.includes('showHidden');
    },
    filteredBundles() {
      if (this.isShowHidden) {
        return this.bundles.filter((bundle) => bundle.additionalData.hidden);
      }
      return this.bundles.filter((bundle) => !bundle.additionalData.hidden);
    },
  },
};
</script>

<style lang="scss">
.select-bundle {
  background-color: $color-white;
  margin-bottom: -9rem;
  padding-bottom: 7rem;
}

.select-bundle__bundles > * + * {
  margin-top: 1.5rem;
}
</style>
